<template>
  <div class="container mx-auto -mt-12 px-8 text-gray-600 mb-16">
    <div class="mb-4">
      <br /><br />
      <div class="columns">
        <div class="column is-6 is-offset-3">
          <div class="my-2 px-4 py-3 bg-blue-100 rounded">
            If you have any issues or suggestions contact Eric
          </div>
        </div>
      </div>
    </div>

    <section class="section">
      <h2 class="text-xl mb-3 font-semibold title">
        Instructions
      </h2>
      <ul class="list-disc ml-8">
        <li>
          Open the slack channel for the client, after the first slash m command
          the DB record will be created for this client
        </li>
        <li>
          type <span class="font-bold text-blue-700">/swot</span> and hit enter
          for the "slash command" that will remind you of the different commands
        </li>
        <li>
          For strengths type
          <span class="font-bold text-blue-700">/st</span> and the text you
          normally would write after the "(S)"
        </li>
        <li>
          For weaknesses type
          <span class="font-bold text-blue-700">/we</span> and the text
        </li>
        <li>
          For opportunities type
          <span class="font-bold text-blue-700">/op</span> and the text
        </li>
        <li>
          For threats type <span class="font-bold text-blue-700">/th</span> and
          the text
        </li>
        <li>
          For general info type
          <span class="font-bold text-blue-700">/in</span> and the text
        </li>
        <li>
          For executive summary type
          <span class="font-bold text-blue-700">/es</span> and the text
        </li>
        <li>
          For notes/slides that should be added to the DD type
          <span class="font-bold text-blue-700">/add</span> and the text
        </li>
        <li>
          For quotes from people in the DD type
          <span class="font-bold text-blue-700">/quote</span> and the text
        </li>
        <li>
          These SWOT commands will be saved to a Firestore DB and accessable on
          this page
        </li>
      </ul>
      <br />
      <h2 class="text-xl mb-3 font-semibold title">Known Issues</h2>
      <ul class="list-disc ml-8">
        <li>
          Not ready if there are multiple DDs done for same company (ie same
          channel)
        </li>
      </ul>
      <br />
      <h2 class="text-xl mb-3 font-semibold title">Roadmap</h2>
      <ul class="list-disc ml-8">
        <li>Add authentication</li>
        <li>
          Adding capability to do multiple DD per channel, will prompt user in
          Slack to add to existing (archived) SWOT or create a new one
        </li>
        <li>Add a way to edit/delete inside Slack channel</li>
        <li>
          Add a way to classify SWOT item to RepGen category inside Slack
          channel
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
  export default {};
</script>

<style>
  ul {
    @apply list-disc ml-8;
  }
  li {
  }
</style>
